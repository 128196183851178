<template>
  <div>
    <v-snackbar v-model="snackbar.status" :color="snackbar.color" :timeout="snackbar.timeout" dark>
      <span class="font-weight-bold">{{ snackbar.text }}</span>
      <template v-slot:action="{ attrs }">
        <v-btn
            class="font-weight-bold"
            color="white"
            text
            v-bind="attrs"
            @click="snackbar.status = false"
        >Fechar
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog v-model="dialogErro.status" persistent max-width="500">
      <CardDialog :dialog="dialogErro"/>
    </v-dialog>
    <v-dialog v-model="dialogProfissionais" fullscreen style="overflow-y: auto;">
      <v-overlay opacity="0.8" class="overlay-fullwidth">
        <v-row class="pa-5">
          <v-col cols="12" md="12">
            <v-card color="primary" dark
                    :style="$vuetify.breakpoint.smAndDown ? 'display: flex; flex-direction: column; max-height: 50vh;' : 'display: flex; flex-direction: column; max-height: 90vh;'">
              <v-card-title>Lista de profissionais relacionados ao cliente - {{ clienteNome }}
                <v-spacer/>
                <v-btn color="white" icon @click="closeDialogProfissional">
                  <v-icon> mdi-close</v-icon>
                </v-btn>
              </v-card-title>
              <v-divider/>
              <v-card-text style="flex: 1; overflow-y: auto;" class="scroll-detalhes pb-0">
                <template v-if="qtdProfissionalCliente > 0">
                  <v-container fluid>
                    <v-data-iterator
                        :items="itemsTeste"
                        :items-per-page.sync="itemsPerPage"
                        :sort-by="sortBy.toLowerCase()"
                        :sort-desc="sortDesc"
                        :page.sync="page"
                        :search="search"
                        hide-default-footer
                    >
                      <template v-slot:header>
                        <v-toolbar dark color="red darken-3" class="mb-1">
                          <v-text-field
                              v-model="search"
                              clearable
                              flat
                              solo-inverted
                              hide-details
                              prepend-inner-icon="mdi-magnify"
                              label="Buscar"
                          ></v-text-field>
                          <template v-if="$vuetify.breakpoint.mdAndUp">
                            <v-spacer></v-spacer>
                            <v-btn-toggle v-model="sortDesc" mandatory>
                              <v-btn large depressed color="white" :value="false">
                                <v-icon color="red darken-3">mdi-arrow-up</v-icon>
                              </v-btn>
                              <v-btn large depressed color="white" :value="true">
                                <v-icon color="red darken-3">mdi-arrow-down</v-icon>
                              </v-btn>
                            </v-btn-toggle>
                          </template>
                        </v-toolbar>
                      </template>

                      <template v-slot:default="props">
                        <v-row>
                          <v-col
                              v-for="item in props.items"
                              :key="item.nome"
                              cols="12"
                              sm="6"
                              md="4"
                              lg="3"
                          >
                            <v-card class="v-card-profissional">
                              <v-card-title class="subheading font-weight-bold">
                                {{ item.nome }}
                              </v-card-title>

                              <v-divider></v-divider>

                              <v-list dense class="v-card-profissional">
                                <v-list-item>
                                  <v-list-item-content>
                                    <strong>Profissão:</strong> {{ item.tipoProfissional }}
                                  </v-list-item-content>
                                </v-list-item>
                                <v-list-item>
                                  <v-list-item-content>
                                    <strong>Validade:</strong> {{ item.dataValidade }}
                                  </v-list-item-content>
                                </v-list-item>
                              </v-list>

                              <v-divider></v-divider>

                              <!-- Barra de Progresso -->
                              <v-card-text>
                                <div class="align-center justify-center">
                                  <span style="font-size: 12px; text-align: center">Validade de permissão do profissional</span>
                                </div>
                                <v-progress-linear
                                    :value="calcularProgresso(item.dataValidade)"
                                    :color="definirCor(item.dataValidade)"
                                    height="15"
                                    rounded
                                >
                                  {{ calcularDiasRestantes(item.dataValidade) }} dias restantes
                                </v-progress-linear>
                              </v-card-text>
                            </v-card>
                          </v-col>
                        </v-row>
                      </template>

                      <template v-slot:footer>
                        <v-row class="mt-2 pl-3 pr-3" align="center" justify="center">
                          <span class="grey--text">Itens por página</span>
                          <v-menu offset-y>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn dark text class="ml-2" v-bind="attrs" v-on="on">
                                {{ itemsPerPage }}
                                <v-icon>mdi-chevron-down</v-icon>
                              </v-btn>
                            </template>
                            <v-list>
                              <v-list-item
                                  v-for="(number, index) in itemsPerPageArray"
                                  :key="index"
                                  @click="updateItemsPerPage(number)"
                              >
                                <v-list-item-title>{{ number }}</v-list-item-title>
                              </v-list-item>
                            </v-list>
                          </v-menu>

                          <v-spacer></v-spacer>

                          <span class="mr-4 grey--text">Página {{ page }} de {{ numberOfPages }}</span>
                          <v-btn fab dark color="red darken-3" class="mr-1" @click="formerPage">
                            <v-icon>mdi-chevron-left</v-icon>
                          </v-btn>
                          <v-btn fab dark color="red darken-3" class="ml-1" @click="nextPage">
                            <v-icon>mdi-chevron-right</v-icon>
                          </v-btn>
                        </v-row>
                      </template>
                    </v-data-iterator>
                  </v-container>


                </template>
                <template v-else>Nenhum profissional relacionado ao cliente.</template>
              </v-card-text>
              <v-card-actions><span></span></v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-overlay>
    </v-dialog>
    <v-dialog v-model="dialogReciboAssinado">
    </v-dialog>
    <v-dialog v-model="dialogGerarRecibo" max-width="700" scrollable persistent>
      <v-card elevation="0">
        <v-card-title class="primary white--text pt-4 pr-3">
          <v-row no-gutters>
            <v-col cols="12">
              <div class="d-flex">
                <span>Recibo de Venda (Online) </span>
                <v-spacer/>
                <v-btn color="white" icon @click="closeDialogGerarRecibo()" class="mt-n2 mr-n1">
                  <v-icon> mdi-close</v-icon>
                </v-btn>
              </div>
            </v-col>
            <v-col cols="12">
              <p class="font-weight-bold text-subtitle-2 mb-0">Cliente: {{ dadosGerarRecibo.nomeClienteRecibo }}
                (Documento: {{ dadosGerarRecibo.pedidoRecibo }})</p>
            </v-col>
          </v-row>
        </v-card-title>
        <v-divider/>
        <v-card-text class="pa-0">
          <v-stepper v-model="step" vertical elevation="0">
            <v-stepper-step
                :complete="step > 1"
                step="1"
                class="font-weight-medium py-3"
            >
              Geração de link
            </v-stepper-step>
            <v-stepper-content step="1" class="py-2 font-weight-black">
              <v-card outlined>
                <v-card-text class="text-center px-4 pb-5 pt-1">
                  <v-select v-model="dadosGerarRecibo.terceiroSelecionado" :items="terceirosAssinar" required outlined
                            dense
                            label="Terceiro autorizado para retirada" item-text="nome" item-value="idclifor"
                            class="pt-3"></v-select>
                  <v-btn :disabled="dadosGerarRecibo.terceiroSelecionado === null" class="primary" width="100%"
                         @click="gerarLinkRecibo()" elevation="0">
                    <v-icon class="mr-2">
                      mdi-play
                    </v-icon>
                    Gerar link de assinatura
                  </v-btn>
                </v-card-text>
              </v-card>
            </v-stepper-content>

            <v-stepper-step
                :complete="step > 2"
                step="2"
                class="font-weight-medium py-5"
            >
              Envio de links de assinatura
            </v-stepper-step>
            <v-stepper-content step="2" class="mx-0 pa-0 font-weight-bold">
              <v-divider/>
              <v-card>
                <v-card-subtitle class="py-3 px-6 red--text text-caption">
                  <p class="ma-0 mb-1"><b>Avisos Importantes:</b></p>
                  <p class="ma-0">1. Não compartilhe o link com ninguém além do assinante autorizado.</p>
                  <p class="ma-0">2. Certifique-se de copiar e compartilhar o link necessário.</p>
                  <p class="ma-0"><strong>3. </strong> Ao gerar uma segunda via, o recibo atual será automaticamente
                    cancelado, e todo o progresso associado a ele será perdido. Certifique-se de compartilhar o novo
                    link gerado para garantir o andamento correto da assinatura do recibo.</p>
                  <v-btn
                      :loading="carregando"
                      outlined
                      color="error"
                      elevation="0"
                      class="mt-2 mb-1"
                      @click="gerarSegundaVia()"
                      width="100%"
                      small
                  >Cancelar recibo atual e Gerar 2ª via
                  </v-btn>
                </v-card-subtitle>
                <v-divider/>
                <v-card-text class="px-6 pb-0 pt-3">
                  <v-row no-gutters>
                    <v-col cols="12" v-if="retornoReciboOnline.retorno">
                      <v-text-field
                          :label="'Link Assinante: ' + dadosGerarRecibo.nomeTerceiroSelecionado"
                          ref="copiar1"
                          dense
                          v-model="retornoReciboOnline.linkRecibo"
                          outlined
                          color="primary"
                          class="mb-5 mt-2"
                          hide-details
                          readonly
                          :disabled="retornoReciboOnline.statusAssinatura === 'A'"
                      >
                        <template v-slot:append>
                          <v-tooltip color="success" left v-if="retornoReciboOnline.statusAssinatura === 'A'">
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                  size="30"
                                  class="mr-1"
                                  color="success"
                                  v-bind="attrs"
                                  v-on="on"
                              >
                                mdi-checkbox-marked-circle
                              </v-icon>
                            </template>
                            <span>Link já assinado</span>
                          </v-tooltip>
                          <template v-else>
                            <v-btn class="mt-n1" color="primary" icon :loading="carregando"
                                   @click="copy(retornoReciboOnline.linkRecibo, 'copiar1')">
                              <v-icon size="20">mdi-content-copy</v-icon>
                            </v-btn>
                            <v-btn class="mt-n1" color="primary" icon :loading="carregando"
                                   @click="gerarQRCode(retornoReciboOnline.linkRecibo,'Assinante:\n' + dadosGerarRecibo.nomeTerceiroSelecionado)">
                              <v-icon size="20">mdi-qrcode-scan</v-icon>
                            </v-btn>
                          </template>
                        </template>
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-stepper-content>
          </v-stepper>
        </v-card-text>

      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogQrcode" max-width="500" width="100%" scrollable>
      <v-card>
        <v-card-title class="primary white--text">
          <v-row no-gutters>
            <v-col cols="12">
              <div class="d-flex">
                <span>Acesso de link via QR Code</span>
                <v-spacer/>
                <v-btn color="white" icon @click="closeDialogQrcode()" class="mb-n6" large>
                  <v-icon> mdi-close</v-icon>
                </v-btn>
              </div>
            </v-col>
            <v-col cols="12">
              <p class="font-weight-bold text-subtitle-2 mb-0">{{ labelSubtitulo }}</p>
            </v-col>
          </v-row>
        </v-card-title>
        <v-divider/>
        <v-card-text class="text-center pa-10">
          <v-container v-show="carregandoQrcode" max-width="480" width="100%">
            <v-progress-circular class="my-16 y-16" size="200" color="black" width="6"
                                 indeterminate></v-progress-circular>
          </v-container>
          <v-dialog-transition leave-absolute>
            <canvas
                v-show="!carregandoQrcode"
                style="border: 6px solid black; width: 100%; max-width: 400px; height: auto;"
                ref="qrcodeCanvas"
            />
          </v-dialog-transition>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-row>
      <v-col cols="12">
        <v-card class="primary">
      <v-card-title class="pa-0">
        <v-tabs v-model="tab" grow color="red darken-3" background-color="primary" dark class="pa-0">
          <v-tabs-slider color="red"></v-tabs-slider>
          <v-tab href="#tab-1" class="primary red--text">
            <v-icon color="red">mdi-receipt-text-plus-outline</v-icon>
            <span class="ml-2">Gerar Recibo</span>
          </v-tab>
          <v-tab href="#tab-2" class="primary red--text ml-3">
            <v-icon color="red">mdi-account-multiple-plus-outline</v-icon>
            <span class="ml-2">Relacionar profissional</span>
          </v-tab>
        </v-tabs>
      </v-card-title>
      <v-card-text>
        <v-tabs-items v-model="tab" class="pa-0">
          <v-tab-item value="tab-1">
            <v-card>
              <div v-if="empresasPiloto.includes(busca.idfilial)">
                <v-divider/>
                <v-progress-linear indeterminate absolute :active="carregando2"/>
                <v-card-title class="title">
                  <v-row no-gutters>
                    <v-col cols="3" class="d-none d-md-block pr-1">
                      <InputDatePicker
                          :disabled="carregando"
                          v-model="busca.dtinicio"
                          label="Data inicial"
                          :outlined="true"
                          :dense="true"
                      />
                    </v-col>
                    <v-col cols="3" class="d-none d-md-block px-1">
                      <InputDatePicker
                          :disabled="carregando"
                          v-model="busca.dtfim"
                          label="Data final"
                          :outlined="true"
                          :dense="true"
                      />
                    </v-col>
                    <v-col cols="3" class="d-none d-md-block px-1">
                      <v-select
                          :items="filiaisFiltro"
                          item-text="filial"
                          item-value="idfilial"
                          label="Filial"
                          outlined
                          dense
                          :disabled="carregando"
                          v-model="busca.idfilial"
                          @change="dadosLista=[];totais={};"
                      ></v-select>
                    </v-col>
                    <v-bottom-sheet class="d-flex d-md-none" v-model="sheet">
                      <v-sheet class="text-center" height="100%">
                        <v-row class="pa-3">
                          <v-col cols="6" class="mb-n3 px-2">
                            <InputDatePicker
                                :disabled="carregando"
                                v-model="busca.dtinicio"
                                label="Data inicial"
                                :outlined="true"
                                :dense="true"
                            />
                          </v-col>
                          <v-col cols="6" class="mb-n3 px-2">
                            <InputDatePicker
                                :disabled="carregando"
                                v-model="busca.dtfim"
                                label="Data final"
                                :outlined="true"
                                :dense="true"
                            />
                          </v-col>
                          <v-col cols="6" class="mb-n3 px-2">
                            <v-select
                                hide-details
                                :items="filiaisFiltro"
                                item-text="filial"
                                item-value="idfilial"
                                label="Filial"
                                outlined
                                dense
                                :disabled="carregando"
                                v-model="busca.idfilial"
                                @change="dadosLista=[];totais={};"
                            ></v-select>
                          </v-col>
                          <v-col cols="6" class="mb-n5 px-2">
                            <v-text-field
                                :disabled="carregando"
                                v-model="busca.busca"
                                label="Buscar"
                                outlined
                                dense
                                @keydown.enter="listarPedidos(0)"
                            />
                          </v-col>
                          <v-col cols="6">
                            <v-btn width="100%" text class="mt-n3" color="error" @click="sheet = !sheet">Cancelar</v-btn>
                          </v-col>
                          <v-col cols="6">
                            <v-btn width="100%" class="mt-n3" color="primary" @click="listarPedidos(0); sheet = !sheet">
                              Filtrar
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-sheet>
                    </v-bottom-sheet>
                    <v-col class="pl-1">
                      <v-text-field
                          class="d-flex d-md-none mt-4"
                          :disabled="carregando"
                          v-model="busca.busca"
                          label="Buscar"
                          outlined
                          dense
                          @keydown.enter="listarPedidos(0)"
                          append-outer-icon="mdi-menu"
                          @click:append-outer="sheet = !sheet"
                          hide-details
                      />
                      <v-text-field
                          class="d-none d-md-block"
                          :disabled="carregando"
                          v-model="busca.busca"
                          label="Buscar"
                          outlined
                          dense
                          @keydown.enter="listarPedidos(0)"
                          hide-details
                      />
                    </v-col>
                    <v-col cols="auto" class="ml-2 mt-0">
                      <v-btn
                          :disabled="carregando"
                          class="mt-1"
                          color="primary"
                          elevation="0"
                          fab
                          x-small
                          @click="listarPedidos(0)"
                      >
                        <v-icon>mdi-magnify</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-title>
                <v-col cols="12" class="pa-0">
                  <v-divider/>
                </v-col>

                <v-card-text class="pt-0 px-0" v-if="$vuetify.breakpoint.mdAndUp">
                  <v-skeleton-loader v-if="carregandoSkeleton" type="table-tbody"/>
                  <v-simple-table v-else>
                    <template v-slot:default>
                      <thead>
                      <tr>
                        <th class="text-left">Filial</th>
                        <th class="text-truncate">Documento</th>
                        <th class="text-left">Cliente</th>
                        <th class="text-left">Data pedido</th>
                        <th class="text-left">Ponto de retirada</th>
                        <th class="text-center">Vinculo com Terceiro</th>
                        <th class="text-center pl-0">Situação Recibo</th>
                      </tr>
                      </thead>
                      <tbody>
                      <template v-if="dadosPedidosLista.total > 0">
                        <tr v-for="(u, i) in dadosPedidosLista.lista" :key="i">
                          <td>{{ u.filial }}</td>
                          <td class="text-truncate">
                            {{ u.idpedido }}
                          </td>
                          <td>{{ u.cliente }}</td>
                          <td>{{ u.dataPedido | formataData }}</td>
                          <td>{{ u.pontoRet }}</td>
                          <td class="text-center" width="300px">
                            <div class="mr-2">
                              <v-btn
                                  rounded
                                  small
                                  color="orange darken-1"
                                  class="white--text"
                                  @click="listarProfissional(u.idclifor, u.cliente)"
                              >
                                <v-icon
                                    left
                                    color="white"
                                >mdi-account-group
                                </v-icon>
                                Profissionais relacionados
                              </v-btn>
                            </div>
                          </td>
                          <td class="text-center pl-0" width="260px">
                            <div class="mr-2" v-if="verificaRecibo(u.idclifor) === 'F'">
                              <v-btn
                                  rounded
                                  small
                                  color="primary"
                                  class="white--text"
                                  @click="openDialogGerarRecibo(u.cliente, u.idpedido, u.dataPedido)"
                              >
                                <v-icon
                                    left
                                    color="white"
                                >mdi-receipt-text-plus-outline
                                </v-icon>
                                Gerar recibo
                              </v-btn>
                            </div>
                            <div class="mr-2" v-else>
                              <v-btn
                                  rounded
                                  small
                                  color="green darken-1"
                                  class="white--text"
                              >
                                <v-icon
                                    left
                                    color="white"
                                >mdi-receipt-text-check-outline
                                </v-icon>
                                Ver Recibo assinado
                              </v-btn>
                            </div>
                          </td>
                        </tr>
                      </template>
                      <template v-else>
                        <tr>
                          <td class="text-center" colspan="7">Nenhum registro encontrado.</td>
                        </tr>
                      </template>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card-text>
              </div>
              <v-divider/>
              <Paginacao
                  class="py-3"
                  :total="dadosPedidosLista.total"
                  :pg="pgSelecionada"
              />
              <v-progress-linear indeterminate absolute :active="carregando"/>
            </v-card>
          </v-tab-item>
          <v-tab-item value="tab-2">
            <template>
              <v-stepper v-model="e1">
                <v-stepper-header>
                  <v-stepper-step
                      :complete="e1 > 1"
                      step="1"
                  >
                    Informações do Cliente
                  </v-stepper-step>

                  <v-divider></v-divider>

                  <v-stepper-step
                      :complete="e1 > 2"
                      step="2"
                  >
                    Informações do Profissional
                  </v-stepper-step>

                  <v-divider></v-divider>

                  <v-stepper-step
                      :complete="e1 > 3"
                      step="3"
                  >
                    Gerar links
                  </v-stepper-step>
                </v-stepper-header>

                <v-stepper-items>
                  <v-stepper-content step="1">
                    <v-card
                        class="mb-12"
                        elevation="0"
                    >
                      <v-card-text>
                        <div>
                          <div class="pb-1">
                            <v-icon class="mr-2">mdi-account-circle</v-icon>
                            <span class="font-weight-bold">Nome:</span>
                          </div>
                          <v-autocomplete
                              class="pa-0"
                              no-filter
                              v-model="dadosGerarRecibo.dadosCliente.idclifor"
                              outlined
                              dense
                              :search-input.sync="searchCliente"
                              :items="itemsClientes"
                              :loading="carregando2"
                              item-text="idenome"
                              item-value="idcliente"
                          ></v-autocomplete>
                        </div>
                        <div>
                          <div class="pb-2">
                            <v-icon class="mr-2">mdi-cellphone</v-icon>
                            <span class="font-weight-bold">Telefone:</span>
                          </div>
                          <v-text-field v-model="dadosGerarRecibo.dadosCliente.telefone" type="text" required outlined
                                        dense label="Telefone Profissional" @input="formatarTelefoneCliente"
                                        maxlength="15"></v-text-field>
                        </div>

                        <fieldset class="px-4 pb-3">
                          <legend>Endereço</legend>
                          <v-row class="py-3">
                            <v-col cols="12" xl="12" lg="12" md="12" sm="12" class="py-0">
                              <div class="text-body-1 font-weight-bold">Rua</div>
                              <v-text-field class="mb-n3" v-model="dadosGerarRecibo.dadosCliente.endereco" outlined dense required maxlength="100"></v-text-field>
                            </v-col>
                            <v-col cols="12" xl="8" lg="8" md="8" sm="8" class="py-0">
                              <div class="text-body-1 font-weight-bold">Bairro</div>
                              <v-text-field class="mb-n3" v-model="dadosGerarRecibo.dadosCliente.bairro" outlined dense required maxlength="100"></v-text-field>
                            </v-col>
                            <v-col cols="12" xl="4" lg="4" md="4" sm="4" class="py-0">
                              <div class="text-body-1 font-weight-bold">Número</div>
                              <v-text-field class="mb-n3" v-model="dadosGerarRecibo.dadosCliente.numero" outlined dense required maxlength="6"></v-text-field>
                            </v-col>
                            <v-col cols="12" xl="12" lg="12" md="12" sm="12" class="py-0">
                              <div class="text-body-1 font-weight-bold">Cidade</div>
                              <v-autocomplete
                                  no-filter
                                  v-model="dadosGerarRecibo.dadosCliente.cidade"
                                  outlined
                                  dense
                                  :search-input.sync="searchCidade"
                                  :items="itemsCidades"
                                  :loading="carregando3"
                                  item-text="cidade"
                                  item-value="idcidade"
                              ></v-autocomplete>

                            </v-col>

                          </v-row>
                        </fieldset>

                      </v-card-text>
                    </v-card>

                    <v-btn
                        :disabled="verificaDadosCliente()"
                        color="primary"
                        block
                        @click="e1 = 2"
                    >
                      Continue
                    </v-btn>
                  </v-stepper-content>

                  <v-stepper-content step="2">
                    <v-card
                        class="mb-12"
                        elevation="0"
                    >
                      <v-card-text>
                        <div>
                          <div class="pb-1">
                            <v-icon class="mr-2">mdi-account-circle</v-icon>
                            <span class="font-weight-bold">Nome:</span>
                          </div>
                          <v-autocomplete
                              class="pa-0"
                              no-filter
                              v-model="dadosGerarRecibo.dadosTerceiro.idclifor"
                              outlined
                              dense
                              :search-input.sync="searchProfissional"
                              :items="itemsProfissionais"
                              :loading="carregando2"
                              item-text="idenome"
                              item-value="idcliente"
                          ></v-autocomplete>
                        </div>
                        <div>
                          <div class="pb-1">
                            <v-icon class="mr-2">mdi-cellphone</v-icon>
                            <span class="font-weight-bold">Telefone:</span>
                          </div>
                          <v-text-field v-model="dadosGerarRecibo.dadosTerceiro.telefone" type="text" required outlined
                                        dense label="Telefone Profissional" @input="formatarTelefoneProfissional"
                                        maxlength="15"></v-text-field>
                        </div>
                        <div>
                          <div class="pb-1">
                            <v-icon class="mr-2">mdi-account-hard-hat</v-icon>
                            <span class="font-weight-bold">Tipo Profissional</span>
                          </div>
                          <v-select class="pa-0" v-model="dadosGerarRecibo.dadosTerceiro.tipoProfissional" :items="tipoProfissional" required
                                    outlined dense
                                    item-text="PROFISSIONAL" item-value="ID"></v-select>
                        </div>
                        <div>
                          <div class="pb-1">
                            <v-icon class="mr-2">mdi-calendar</v-icon>
                            <span class="font-weight-bold">Data Validade Permissão</span>
                          </div>
                          <InputDatePicker :disabled="carregando" v-model="novoProfissional.dataValidade"
                                           :outlined="true" :dense="true" @change="verificaDataValidade()"/>
                        </div>
                      </v-card-text>
                    </v-card>

                    <v-col cols="12">
                      <v-btn
                          text
                          @click="e1 = 1"
                          block
                      >
                        Voltar
                      </v-btn>
                      <v-btn
                          color="primary"
                          @click="e1 = 3"
                          block
                      >
                        Gerar Permissão
                      </v-btn>
                    </v-col>
                  </v-stepper-content>

                  <v-stepper-content step="3">
                    <v-card>
                      <v-card-subtitle class="py-3 px-6 red--text text-caption">
                        <p class="ma-0 mb-1"><b>Avisos Importantes:</b></p>
                        <p class="ma-0">1. Não compartilhe o link com ninguém além do assinante autorizado.</p>
                        <p class="ma-0">2. Certifique-se de copiar e compartilhar o link necessário.</p>
                        <p class="ma-0"><strong>3. </strong> Ao gerar uma segunda via, o recibo atual será automaticamente
                          cancelado, e todo o progresso associado a ele será perdido. Certifique-se de compartilhar o novo
                          link gerado para garantir o andamento correto da assinatura do recibo.</p>
                        <v-btn
                            :loading="carregando"
                            outlined
                            color="error"
                            elevation="0"
                            class="mt-2 mb-1"
                            @click="gerarSegundaVia()"
                            width="100%"
                            small
                        >Cancelar recibo atual e Gerar 2ª via
                        </v-btn>
                      </v-card-subtitle>
                      <v-divider/>
                      <v-card-text class="px-6 pb-0 pt-3">
                        <v-row no-gutters>
                          <v-col cols="12" v-if="retornoReciboOnline.retorno">
                            <v-text-field
                                :label="'Link Assinante: ' + dadosGerarRecibo.nomeTerceiroSelecionado"
                                ref="copiar1"
                                dense
                                v-model="retornoReciboOnline.linkRecibo"
                                outlined
                                color="primary"
                                class="mb-5 mt-2"
                                hide-details
                                readonly
                                :disabled="retornoReciboOnline.statusAssinatura === 'A'"
                            >
                              <template v-slot:append>
                                <v-tooltip color="success" left v-if="retornoReciboOnline.statusAssinatura === 'A'">
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                        size="30"
                                        class="mr-1"
                                        color="success"
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                      mdi-checkbox-marked-circle
                                    </v-icon>
                                  </template>
                                  <span>Link já assinado</span>
                                </v-tooltip>
                                <template v-else>
                                  <v-btn class="mt-n1" color="primary" icon :loading="carregando"
                                         @click="copy(retornoReciboOnline.linkRecibo, 'copiar1')">
                                    <v-icon size="20">mdi-content-copy</v-icon>
                                  </v-btn>
                                  <v-btn class="mt-n1" color="primary" icon :loading="carregando"
                                         @click="gerarQRCode(retornoReciboOnline.linkRecibo,'Assinante:\n' + dadosGerarRecibo.nomeTerceiroSelecionado)">
                                    <v-icon size="20">mdi-qrcode-scan</v-icon>
                                  </v-btn>
                                </template>
                              </template>
                            </v-text-field>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>



                    <v-btn
                        color="primary"
                    >
                      Continue
                    </v-btn>
                  </v-stepper-content>


                </v-stepper-items>
              </v-stepper>
            </template>


          </v-tab-item>

        </v-tabs-items>

      </v-card-text>
    </v-card>
      </v-col>







    </v-row>
  </div>
</template>

<script>
import {mapState} from "vuex";
import axios from "axios";
import mixinFilial from "../../util/mixinFilial";
import Paginacao from "../Widgets/Paginacao";
import CardDialog from "../Widgets/CardDialog";
import InputDatePicker from "../Widgets/InputDatePicker";
import jsPDF from "jspdf";
import autoTable from 'jspdf-autotable';
import _ from "lodash";
import QRCode from "qrcode";

export default {
  name: "ContratoPermissaoTerceiros",
  mixins: [mixinFilial],
  components: {CardDialog, Paginacao, InputDatePicker},
  data: () => ({

    tab: null,
    e1: 1,

    itemsPerPageArray: [4, 8, 12],
    search: '',
    sortBy: 'nome',
    page: 1,
    itemsPerPage: 4,
    sortDesc: false,
    itemsTeste: [
      {
        nome: 'Mariana Costa',
        tipoProfissional: 'Arquiteto',
        dataValidade: '2025-04-22',
      },
      {
        nome: 'Pedro Lima',
        tipoProfissional: 'Pintor',
        dataValidade: '2025-04-30',
      },
      {
        nome: 'Carla Mendes',
        tipoProfissional: 'Mestre de Obra',
        dataValidade: '2025-02-05',
      },
      {
        nome: 'Fernanda Almeida',
        tipoProfissional: 'Arquiteto',
        dataValidade: '2025-07-08',
      },
      {
        nome: 'Lucas Oliveira',
        tipoProfissional: 'Engenheiro',
        dataValidade: '2025-10-29',
      },
      {
        nome: 'Gustavo Ferreira',
        tipoProfissional: 'Pedreiro',
        dataValidade: '2025-01-20',
      },
    ],


    aguardarBusca: null,
    aguardarBuscaCliente: null,
    searchProfissional: null,
    searchCliente: null,
    searchCidade: null,
    clientesLista: [],
    profissionaisLista: [],

    terceirosAssinar: [
      {
        idclifor: 975,
        terceiro: 'Mariana Costa',
        nome: 'Mariana Costa - Arquiteto',
      },
      {
        idclifor: 723,
        terceiro: 'Pedro Lima',
        nome: 'Pedro Lima - Pintor',
      },
      {
        idclifor: 12858,
        terceiro: 'Fernanda Almeida',
        nome: 'Fernanda Almeida - Engenheiro',
      },
    ],
    dadosGerarRecibo: {
      idclifor: null,
      nomeClienteRecibo: null,
      pedidoRecibo: null,
      dataPedidoRecibo: null,

      empresaEndereco: 'Av. Paraná',
      empresaNumero: '1340',
      empresaBairro: 'Centro',
      empresaCidade: 'Ivaiporã',
      empresaCep: '86870-000',
      empresaCNPJ: '00000000000',
      valorRecibo: 240.00,
      valorExtenso: 'DUZENTOS E QUARENTA REIAS',
      reciboGerado: false,
      produtosRecibo: [
        {
          nome: 'Tijolo',
          qtd: '20',
          valor: '100.00'
        },
        {
          nome: 'Cimento',
          qtd: '10',
          valor: '100.00'
        },
        {
          nome: 'Argamassa',
          qtd: '4',
          valor: '40.00'
        },
      ],
      dadosTerceiro:{
        idclifor: null,
        nome: null,
        telefone: null,
        tipoProfissional: null,
      },
      dadosCliente: {
        idclifor: null,
        telefone: null,
        cpf: null,
        endereco: null,
        numero: null,
        bairro: null,
        cidade: null,
        cep: null,
        uf: null,
      }
    },

    retornoReciboOnline: {
      retorno: false,
      linkRecibo: 'https://asdhgkajshdjkashduiwakjdnaciucasasldjhasiud/asdjnaskd_4564a2s1d56',
      statusAssinatura: 'NA'
    },
    novoProfissional: {
      idclifor: null,
      telefone: null,
      tipoProfissional: null,
      dataValidade: null,
    },
    profissionaisCliente: [],
    qtdProfissionalCliente: null,
    clienteNome: null,

    tipoProfissional: [],
    qtdProfissionais: [
      {
        idclifor: 6545,
        qtd: 0
      },
      {
        idclifor: 156,
        qtd: 3
      },
      {
        idclifor: 983,
        qtd: 0
      },
      {
        idclifor: 347,
        qtd: 2
      },
    ],
    dadosPedidosLista: {
      lista: [
        {
          idfilial: 1,
          filial: 'Ivaiporã',
          idpedido: 65456,
          idclifor: 6545,
          cliente: 'Cliente 1',
          pontoRet: 'Cliente Retira',
          dataPedido: '2025-02-02',
          reciboAssinado: 'T',
        },
        {
          idfilial: 1,
          filial: 'Ivaiporã',
          idpedido: 65456,
          idclifor: 156,
          cliente: 'Cliente 2',
          pontoRet: 'Cliente Retira',
          dataPedido: '2025-02-02',
          reciboAssinado: 'F',
        },
        {
          idfilial: 1,
          filial: 'Ivaiporã',
          idpedido: 65456,
          idclifor: 983,
          cliente: 'Cliente 3',
          pontoRet: 'Cliente Retira',
          dataPedido: '2025-02-02',
          reciboAssinado: 'F',
        },
        {
          idfilial: 1,
          filial: 'Ivaiporã',
          idpedido: 65456,
          idclifor: 347,
          cliente: 'Cliente 4',
          pontoRet: 'Cliente Retira',
          dataPedido: '2025-02-02',
          reciboAssinado: 'F',
        }
      ],
      total: 4
    },

    isVisible: false,
    isVisible2: false,

    pdfUrl: null,
    empresasPiloto: [1, 3],
    ultimaEmpresa: 0,
    dialogProfissionais: false,
    dialogReciboAssinado: false,
    dialogGerarRecibo: false,
    dialogQrcode: false,
    dialogErro: {
      status: false,
      title: "",
      msg: "",
      icon: "",
      iconColor: "",
    },

    sheet: false,
    snackbar: {status: false, timeout: 3000, color: 'red'},

    carregando: false,
    carregandoQrcode: false,
    carregandoSkeleton: false,
    carregandoTotais: true,

    cidades: [],
    aguardarBuscaCidade: null,


    search1: null,
    search2: null,
    search3: null,
    search4: null,



    clientes1: [],
    clientes2: [{status_assinatura: false}],
    clientes3: [{status_assinatura: false}],
    clientes4: [],

    carregando1: false,
    carregando2: false,
    carregando3: false,
    carregando4: false,

    errorT1: false,
    errorT2: false,

    labelSubtitulo: '',

    label1: '',
    label2: '',
    label3: '',
    label4: '',

    contratoGerado: false,
    step: 1,
    dadosContrato: {existeContrato: false},

    contrato: {},

    dados: {},
    imagens: [],
    contratoPDF: "",
    diretorio: "",
    base64PDF: "",
    ArquivoBlob: "",

    dadosLista: {},
    totais: {},
    busca: {
      dtinicio: `${new Date().getFullYear()}-${
          (new Date(
              new Date().getFullYear(),
              new Date().getMonth() + 1,
              0
          ).getMonth() +
          1 <
          10
              ? "0"
              : "") +
          (new Date().getMonth() + 1)
      }-01`,
      dtfim: `${new Date(
          new Date().getFullYear(),
          new Date().getMonth() + 1,
          0
      ).getFullYear()}-${
          (new Date(
              new Date().getFullYear(),
              new Date().getMonth() + 1,
              0
          ).getMonth() +
          1 <
          10
              ? "0"
              : "") +
          (new Date().getMonth() + 1)
      }-${new Date(
          new Date().getFullYear(),
          new Date().getMonth() + 1,
          0
      ).getDate()}`,
      idstatus: null,
      idsituacao: null,
      idorcamento: null,
      idcliente: null,
      idfilial: null,
    },
    pgSelecionada: 0,
  }),
  computed: {
    ...mapState(["backendUrl", "pgLimit", "usuario"]),
    filiaisFiltro() {
      if (this.filiais.lista) {
        return this.filiais.lista.filter((v) => {
          return (
              v.idfilial !== 6 &&
              v.idfilial !== 12 &&
              v.idfilial !== 25 &&
              v.idfilial !== 29 &&
              v.idfilial !== 33 &&
              v.idfilial !== 37
          );
        });
      } else {
        return [];
      }
    },
    itemsClientes() {
      if (this.clientesLista == null) return;

      this.clientesLista.forEach((cliente) => {
        if (cliente.nome.length > this.nomeLimit) {
          cliente.nome =
              cliente.nome.slice(0, this.nomeLimit) + "...";
        }
      });

      return this.clientesLista;
    },
    itemsProfissionais() {
      if (this.profissionaisLista == null) return;

      this.profissionaisLista.forEach((profissional) => {
        if (profissional.nome.length > this.nomeLimit) {
          profissional.nome =
              profissional.nome.slice(0, this.nomeLimit) + "...";
        }
      });

      return this.profissionaisLista;
    },
    itemsCidades() {
      if (this.cidades == null) return;
      this.cidades.forEach((cidade1) => {
        if (cidade1.cidade.length > this.nomeLimit) {
          cidade1.cidade =
              cidade1.cidade.slice(0, this.nomeLimit) + "...";
        }
      });
      return this.cidades;
    },
    numberOfPages() {
      return Math.ceil(this.itemsTeste.length / this.itemsPerPage)
    },
  },
  methods: {
    verificaDadosCliente() {
      const telefoneValido = this.dadosGerarRecibo.dadosCliente.telefone?.toString().trim().length >= 10;

      const ClientePreenchido = this.dadosGerarRecibo.dadosCliente.idclifor?.toString().trim() && telefoneValido;

      const enderecoPreenchido = (
          this.dadosGerarRecibo.dadosCliente.endereco?.toString().trim() &&
          this.dadosGerarRecibo.dadosCliente.cidade?.toString().trim() &&
          this.dadosGerarRecibo.dadosCliente.bairro?.toString().trim() &&
          this.dadosGerarRecibo.dadosCliente.numero?.toString().trim()
      );

      return (
          !(ClientePreenchido) || !(enderecoPreenchido)
      )
    },
    verificaDataValidade() {
      let dataHoje = new Date();
      dataHoje.setHours(0, 0, 0, 0); // Zerar horas para garantir comparação apenas por data

      // Converter corretamente a data para evitar deslocamento de fuso horário
      let dataLancada = new Date(this.formVisitaExistente.dataConclusao + "T00:00:00");

      if (isNaN(dataLancada.getTime())) {
        console.error("Data inválida:", this.formVisitaExistente.dataConclusao);
        return;
      }
    },
    formatarTelefoneCliente() {
      let numero = this.dadosGerarRecibo.dadosCliente.telefone.replace(/\D/g, ''); // Remove tudo que não for número

      // Limita o número a no máximo 11 dígitos (para suportar telefones com 9 dígitos no Brasil)
      numero = numero.slice(0, 11);

      if (numero.length > 10) {
        this.dadosGerarRecibo.dadosCliente.telefone = numero.replace(/^(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
      } else {
        this.dadosGerarRecibo.dadosCliente.telefone = numero.replace(/^(\d{2})(\d{4})(\d{0,4})/, '($1) $2-$3');
      }
    },
    formatarTelefoneProfissional() {
      let numero = this.dadosGerarRecibo.dadosTerceiro.telefone.replace(/\D/g, ''); // Remove tudo que não for número

      // Limita o número a no máximo 11 dígitos (para suportar telefones com 9 dígitos no Brasil)
      numero = numero.slice(0, 11);

      if (numero.length > 10) {
        this.dadosGerarRecibo.dadosTerceiro.telefone = numero.replace(/^(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
      } else {
        this.dadosGerarRecibo.dadosTerceiro.telefone = numero.replace(/^(\d{2})(\d{4})(\d{0,4})/, '($1) $2-$3');
      }
    },
    carregarClientes(val) {
      clearTimeout(this.aguardarBuscaCliente);
      this.aguardarBuscaCliente = setTimeout(() => {
        this.carregando2 = true;
        return axios
            .post(`${this.backendUrl}cliente/listar`, {
              limit: 30,
              offset: 0,
              busca: val || null,
            })
            .then((res) => {
              this.clientesLista = res.data.lista.map((v) => {
                return {
                  ...v,
                  idenome: `${v.idcliente} - ${v.nome}`,
                  telefone: `${v.telefoneComercial}`
                };
              });
              this.carregando2 = false;
            })
            .catch(() => {
              this.carregando2 = false;
            });
      }, this.timeLimit);
    },
    carregarProfissional(val) {
      clearTimeout(this.aguardarBusca);
      this.aguardarBusca = setTimeout(() => {
        this.carregando2 = true;
        return axios
            .post(`${this.backendUrl}cliente/listar`, {
              limit: 30,
              offset: 0,
              busca: val || null,
            })
            .then((res) => {
              this.profissionaisLista = res.data.lista.map((v) => {
                return {
                  ...v,
                  idenome: `${v.idcliente} - ${v.nome}`,
                  telefone: `${v.telefoneComercial}`
                };
              });
              this.carregando2 = false;
            })
            .catch(() => {
              this.carregando2 = false;
            });
      }, this.timeLimit);
    },
    carregarCidades(val) {
      clearTimeout(this.aguardarBuscaCidade);
      this.aguardarBuscaCidade = setTimeout(() => {
        this.carregando3 = true;
        return axios
            .post(`${this.backendUrl}cidade/listar`, {
              busca: val || null,
            })
            .then((res) => {
              this.cidades = res.data.map((v) => {
                return {
                  ...v,
                  selectCidade: `${v.cidade} - ${v.uf}`,
                };
              });
              this.carregando3 = false;
            })
            .catch(() => {
              this.carregando3 = false;
            });
      }, this.timeLimit);
    },
    listaTipoProfissional() {
      this.tipoProfissional = [];
      this.carregando = true;
      return axios
          .post(`${this.backendUrl}dashboard/controle/visitatipoprofissional`, {})
          .then((res) => {
            this.tipoProfissional = JSON.parse(res.data.tipoprofissional) || [];
            this.carregando = false;
          })
          .catch(() => {
            this.carregando = false;
          });
    },
    verificaProfissionais(id) {
      const profissionais = this.qtdProfissionais.find(item => item.idclifor === id);
      return profissionais ? profissionais.qtd : 0;
    },
    verificaRecibo(id) {
      const validaRecibo = this.dadosPedidosLista.lista.find(item => item.idclifor === id);
      return validaRecibo.reciboAssinado;
    },
    openDialogGerarRecibo(nome, idpedido, data) {
      this.dadosGerarRecibo.nomeClienteRecibo = nome;
      this.dadosGerarRecibo.pedidoRecibo = idpedido;
      this.dadosGerarRecibo.dataPedidoRecibo = data;
      this.dialogGerarRecibo = true;
    },
    cadastrarProfissional() {
      return axios
          .post(`${this.backendUrl}permissoes/cliente/profissionais/cadastrar`, {
            idclifor: this.novoProfissional.idcliente,
            telefone: this.novoProfissional.telefone,
            tipoprofissional: this.novoProfissional.tipoProfissional,
            datavalidade: this.novoProfissional.dataValidade
          })
          .then(() => {
            this.dialogErro.title = "Sucesso";
            this.dialogErro.msg = "Visita editada com sucesso!";
            this.dialogErro.icon = "mdi-check-circle-outline";
            this.dialogErro.iconColor = "success";
            this.dialogErro.status = true;
            this.carregando2 = false;
            this.limparCadastro();
          })
          .catch(() => {
            this.dialogErro.title = "Erro";
            this.dialogErro.msg = "Erro ao inserir profissional!";
            this.dialogErro.icon = "mdi-alert-circle-outline";
            this.dialogErro.iconColor = "red";
            this.dialogErro.status = true;
            this.limparCadastro();
            this.carregando2 = false;
          });
    },
    listarProfissional(id, nome) {
      this.dialogProfissionais = true;
      this.profissionaisCliente = [];
      this.qtdProfissionalCliente = this.verificaProfissionais(id);
      this.clienteNome = nome;
      this.carregando3 = true;
      return axios
          .post(`${this.backendUrl}permissoes/cliente/profissionais/listar`, {
            idclifor: id
          })
          .then((res) => {
            this.profissionaisCliente = res.data || [];
            this.carregando3 = false;
          })
          .catch(() => {
            this.carregando3 = false;
          });
    },
    gerarLinkRecibo() {
      this.step = 2;
      this.retornoReciboOnline.retorno = true;
      this.gerarRecibo();
    },
    listarPedidos() {

    },

    gerarRecibo() {
      this.dadosGerarRecibo.reciboGerado = true;
      setTimeout(async () => {
        const doc = new jsPDF("p", "pt", "a4");

        // const pageWidth = doc.internal.pageSize.getWidth();
        const margin = 16;
        let yPos = 20;

        doc.setFontSize(10);
        doc.setFont("helvetica", "normal");

        if (this.dadosGerarRecibo.pedidoRecibo) {
          doc.text(`Pedido N° ${this.dadosGerarRecibo.pedidoRecibo}`, margin, yPos, {maxWidth: 555});
          yPos += 12;
        }



        autoTable(doc, {
          startY: yPos,
          body:[
            [
              {content: `ESPACO DA LOGO`,  styles: { fontStyle: "bold"}},
              {
                content: `RECIBO DE PEDIDO N° ${this.dadosGerarRecibo.pedidoRecibo}`,
                styles: {fontStyle: "bold"}
              },
              {
                content: `VALOR R$ ${this.dadosGerarRecibo.valorRecibo}`,
                styles: {fontStyle: "bold"}
              }
            ],
          ],
          styles: {
            fontSize: 8,
            cellPadding: 3,
            lineColor: [0, 0, 0],
            lineWidth: 0.5
          },
          margin: {top: 15, left: 15, right: 15},
          theme: "grid",
          tableWidth: "auto"
        });

        yPos += 12;
        // autoTable(doc, {
        //   startY: yPos,
        //   body: [
        //     [
        //       {
        //         content: `Nome\n${this.dadosGerarRecibo.nomeClienteRecibo || ""}`,
        //         colSpan: 3,
        //         styles: {halign: "left", fontStyle: "bold"}
        //       },
        //       {
        //         content: `RG\n${this.$options.filters.formataRg(this.dadosGerarRecibo.dadosCliente?.rg) || ""}`,
        //         styles: {fontStyle: "bold"}
        //       },
        //       {
        //         content: `CPF\n${this.$options.filters.formataCnpjCpf(this.dadosGerarRecibo.dadosCliente?.cpf) || ""}`,
        //         styles: {fontStyle: "bold"}
        //       }
        //     ],
        //     [
        //       {content: `Endereço\n${this.dadosGerarRecibo.dadosCliente?.endereco || ""}`, styles: {fontStyle: "bold"}},
        //       {content: `Número\n${this.dadosGerarRecibo.dadosCliente?.numero || ""}`, styles: {fontStyle: "bold"}},
        //       {content: `Bairro\n${this.dadosGerarRecibo.dadosCliente?.bairro || ""}`, styles: {fontStyle: "bold"}},
        //       {content: `Cidade\n${this.dadosGerarRecibo.dadosCliente?.cidade || ""}`, styles: {fontStyle: "bold"}},
        //       {
        //         content: `CEP\n${this.$options.filters.formataCep(this.dadosGerarRecibo.dadosCliente?.cep) || ""}`,
        //         styles: {fontStyle: "bold"}
        //       }
        //     ]
        //   ],
        //   styles: {
        //     fontSize: 8,
        //     cellPadding: 3,
        //     lineColor: [0, 0, 0],
        //     lineWidth: 0.5
        //   },
        //   margin: {top: 10, left: 15, right: 15},
        //   theme: "grid",
        //   tableWidth: "auto"
        // });

        let clausulas = [
          `Recibo de produtos: bens descritos na NF-e/DANF-e/Pedido nº ${this.dadosGerarRecibo.pedidoRecibo || ""}`,
          `${this.$options.filters.formataDinheiro(this.dadosGerarRecibo.valorRecibo) || ""} (${this.dadosGerarRecibo.valorExtenso || ""})`,
          `${this.dadosGerarRecibo.dataPedidoRecibo || ""}`,
          `Eu, ${this.dadosGerarRecibo.nomeTerceiroSelecionado || ""}, portador do documento ${this.dadosGerarRecibo.empresaCNPJ || ""}, declaro ter retirado os seguintes produtos:`
        ];

        this.dadosGerarRecibo.produtosRecibo?.forEach(produto => {
          clausulas.push(`- ${produto.nome || ""} (Qtd: ${produto.qtd || 0}) - Valor: R$ ${produto.valor || 0}`);
        });

        clausulas.push(
            `Estando devidamente autorizado pelo dono das mercadorias: ${this.dadosGerarRecibo.nomeClienteRecibo || ""}, portador do documento ${this.dadosGerarRecibo.empresaCNPJ || ""}`
        );

        yPos += 70;
        doc.text(clausulas, 20, yPos)

        doc.save("recibo.pdf");

        // gerar arquivo
        const blob = doc.output("blob");
        const url = URL.createObjectURL(blob);
        window.open(url, "_blank");
      }, 100);
    },


    calcularDiasRestantes(dataValidade) {
      const hoje = new Date();
      const validade = new Date(dataValidade);
      const diff = Math.ceil((validade - hoje) / (1000 * 60 * 60 * 24));
      return diff > 0 ? diff : 0;
    },

    calcularProgresso(dataValidade) {
      const totalDias = 180; // Ajuste conforme necessário
      const diasRestantes = this.calcularDiasRestantes(dataValidade);
      return (diasRestantes / totalDias) * 100;
    },

    definirCor(dataValidade) {
      const dias = this.calcularDiasRestantes(dataValidade);
      if (dias <= 5) return "red";
      if (dias <= 15) return "yellow";
      return "green";
    },

    nextPage() {
      if (this.page + 1 <= this.numberOfPages) this.page += 1
    },
    formerPage() {
      if (this.page - 1 >= 1) this.page -= 1
    },
    updateItemsPerPage(number) {
      this.itemsPerPage = number
    },
    closeDialogProfissional() {
      this.dialogProfissionais = false;
      this.novoProfissional = {};
      this.clienteNome = null;
    },
    closeDialogGerarRecibo() {
      this.dialogGerarRecibo = false;
      this.dadosGerarRecibo.nomeClienteRecibo = null;
      this.dadosGerarRecibo.pedidoRecibo = null;
      this.step = 1;
    },
    closeDialogQrcode() {
      this.dialogQrcode = false;

      setTimeout(() => {
        this.labelSubtitulo = '';
        const canvas = this.$refs.qrcodeCanvas
        const context = canvas.getContext('2d')
        context.clearRect(0, 0, canvas.width, canvas.height)
      }, 500)
    },

    gerarSegundaVia() {
      this.dadosGerarRecibo.nomeTerceiroSelecionado = null;
      this.dadosGerarRecibo.terceiroSelecionado = null;
      setTimeout(() => {
        this.step = 1;
      }, 300);
    },

    limparCadastro() {
      this.novoProfissional.idclifor = null;
      this.novoProfissional.tipoProfissional = null;
      this.novoProfissional.dataValidade = '';
      this.novoProfissional.telefone = null;
    },

    // Função para capturar e formatar a data
    formatDate(segundosAdicionais = 0) {
      // Criar a data atual
      const now = new Date();

      // Adicionar os segundos extras
      now.setSeconds(now.getSeconds() + segundosAdicionais);

      // Nome dos meses em português
      const meses = [
        "Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho",
        "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"
      ];

      // Obter as partes da data
      const dia = now.getDate(); // Dia do mês
      const mes = meses[now.getMonth()]; // Nome do mês
      const ano = now.getFullYear(); // Ano
      const horas = now.getHours().toString().padStart(2, "0"); // Horas (2 dígitos)
      const minutos = now.getMinutes().toString().padStart(2, "0"); // Minutos (2 dígitos)
      const segundos = now.getSeconds().toString().padStart(2, "0"); // Segundos (2 dígitos)

      // Montar a string no formato desejado
      return `${dia} ${mes} ${ano}, ${horas}:${minutos}:${segundos}`;
    },
    copy(link, inputN) {
      var copyTextarea = this.$refs[inputN].$refs.input;
      copyTextarea.select();
      try {
        var successful = document.execCommand("copy");
        var msg = successful ? "sim!" : "não!";
        if (successful) {
          msg = `"${link}" foi copiado para sua área de transferência`;
        } else {
          msg =
              "Não foi possível copiar o texto, use o atalho Crtl+C.";
        }
        this.snackbar.color = 'primary';
        this.snackbar.text = msg;
        this.snackbar.status = true;
      } catch (err) {
        this.snackbar.color = 'red';
        this.snackbar.text =
            "Não foi possível copiar o texto, use o atalho Crtl+C.";
        this.snackbar.status = true;
      }
    },
    gerarQRCode(link, label) {
      this.carregandoQrcode = true;
      this.labelSubtitulo = label;
      this.dialogQrcode = true;
      // Gera o QR Code e o renderiza no canvas
      setTimeout(() => {
        QRCode.toCanvas(this.$refs.qrcodeCanvas, link, {width: 400}, (error) => {
          if (error) console.error(error)
          else console.log("QR Code gerado com sucesso!")
        })
        this.carregandoQrcode = false;
      }, 1000)
    },

    async init() {
      this.busca.idfilial = this.usuario.idfilial;
      const json = localStorage.getItem("filtros_contratos");
      const filtro = JSON.parse(json);
      if (filtro) {
        this.busca = filtro;
      }
      await this.listaTipoProfissional();
    },
  },
  watch: {
    async search1(val) {
      this.pesquisaClientes(val, 1);
    },
    search2: {
      handler: _.debounce(function (val) {
        if (!val || val.length < 1) {
          this.clientes2 = this.ultimasTestemunhas;
        } else {
          this.carregando2 = true;
          this.pesquisaClientes(val, 2);
        }
      }, 600),
      immediate: false
    },
    search3: {
      handler: _.debounce(function (val) {
        if (!val || val.length < 1) {
          this.clientes3 = this.ultimasTestemunhas;
        } else {
          this.carregando3 = true;
          this.pesquisaClientes(val, 3);
        }
      }, 600),
      immediate: false
    },
    async search4(val) {
      this.pesquisaClientes(val, 4);
    },
    searchProfissional: function (val) {
      this.carregarProfissional(val);
    },
    searchCliente: function (val) {
      this.carregarClientes(val);
    },
    searchCidade: function (val){
      this.carregarCidades(val);
    },
    "novoProfissional.idclifor"(novoValor) {
      const profissionalSelecionado = this.clientes.find(c => c.idcliente === novoValor);
      console.log(profissionalSelecionado);
      this.novoProfissional.telefone = profissionalSelecionado ? profissionalSelecionado.endereco.telefoneComercial : "";
    },
    "dadosGerarRecibo.dadosCliente.idclifor"(novoValor) {
      const clienteSelecionado = this.clientesLista.find(c => c.idcliente === novoValor);
      this.dadosGerarRecibo.dadosCliente.telefone = clienteSelecionado ? clienteSelecionado.endereco.telefoneComercial : "";
      this.dadosGerarRecibo.dadosCliente.endereco = clienteSelecionado ? clienteSelecionado.endereco.endereco : "";
      this.dadosGerarRecibo.dadosCliente.numero = clienteSelecionado ? clienteSelecionado.endereco.numero : "";
      this.dadosGerarRecibo.dadosCliente.bairro = clienteSelecionado ? clienteSelecionado.endereco.bairro : "";
      this.dadosGerarRecibo.dadosCliente.cidade = clienteSelecionado ? clienteSelecionado.idcidade : "";
      this.dadosGerarRecibo.dadosCliente.uf = clienteSelecionado ? clienteSelecionado.endereco.uf : "";
      this.dadosGerarRecibo.dadosCliente.cpf = clienteSelecionado ? clienteSelecionado.cpfcnpj: "";

      this.carregarCidades();
    },
    "dadosGerarRecibo.dadosTerceiro.idclifor"(novoValor) {
      const profissionalSelecionado = this.profissionaisLista.find(c => c.idcliente === novoValor);
      console.log(profissionalSelecionado);
      this.dadosGerarRecibo.dadosTerceiro.telefone = profissionalSelecionado ? profissionalSelecionado.endereco.telefoneComercial : "";
      this.dadosGerarRecibo.dadosTerceiro.nome = profissionalSelecionado ? profissionalSelecionado.nome : "";
    }
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.checkScrollPosition);
  },
  created() {
    this.init();
  },
  mounted() {
    window.addEventListener('scroll', this.checkScrollPosition);
    this.carregarProfissional();
    this.carregarCidades();
    this.carregarClientes();
  },
};
</script>

<style scoped>
.overlay-fullwidth {
  display: block;
}

.v-card-profissional {
  background-color: #3D7AA8;
}

::v-deep .scroll-detalhes {
  box-shadow: none !important;
  scrollbar-width: thin; /* Largura da barra de rolagem */
  scrollbar-color: #f1f1f1 #023449; /* Cor da barra de rolagem e da trilha */
}

/* Estilizando a barra de rolagem para navegadores WebKit */
::v-deep .scroll-detalhes::-webkit-scrollbar {
  width: 8px; /* Largura da barra de rolagem */
}

::v-deep .scroll-detalhes::-webkit-scrollbar-track {
  background: #f1f1f1; /* Cor de fundo da trilha */
}

::v-deep .scroll-detalhes::-webkit-scrollbar-thumb {
  background-color: #f1f1f1; /* Cor da barra de rolagem */
  border-radius: 10px; /* Bordas arredondadas */
  border: 2px solid #023449; /* Espaço ao redor da barra de rolagem */
}

.chip-clip {
  cursor: pointer;
}


::v-deep .custom-chip .v-chip {
  background-color: #023449 !important;
  border-radius: 5px !important;
  color: white !important;
  height: 25px !important;
}

::v-deep .dialog-detalhes {
  box-shadow: none !important;
  scrollbar-width: thin; /* Largura da barra de rolagem */
  scrollbar-color: #f1f1f1 #023449; /* Cor da barra de rolagem e da trilha */
}

/* Estilizando a barra de rolagem para navegadores WebKit */
::v-deep .dialog-detalhes::-webkit-scrollbar {
  width: 8px; /* Largura da barra de rolagem */
}

::v-deep .dialog-detalhes::-webkit-scrollbar-track {
  background: #f1f1f1; /* Cor de fundo da trilha */
}

::v-deep .dialog-detalhes::-webkit-scrollbar-thumb {
  background-color: #f1f1f1; /* Cor da barra de rolagem */
  border-radius: 10px; /* Bordas arredondadas */
  border: 2px solid #023449; /* Espaço ao redor da barra de rolagem */
}

</style>